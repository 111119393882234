<template>
  <div class="mx-auto subcat-navigation default" :class="[`columns-${getSubCategories.length}`]">
    <div class="first-level-nav level-0 animate__animated animate__fadeIn animate__slideInUp" v-for="(times, index) in getSubCategories.length" :key="times" :class="[`nav-0-${times}`, `animate__delay-0${index < 2 ? 2 + index : index + 1 }s`]">
      <!-- <h3 class="title">{{getSubCategories[index].name}} {{ getSubCategories[index].id === 1079 }}</h3>
      <ul class="level-1 nav flex-column justify-content-center" :class="{ pluscategory: getSubCategories[index].childrenData.length > 11 && getSubCategories[index].id === 1079, hiddenVoice: hiddenVoice}"> -->
      <h3 class="title">{{getSubCategories[index].name}}</h3>
      <!-- <ul class="level-1 nav flex-column justify-content-center"> -->
        <ul class="level-1 nav flex-column justify-content-center" :class="{ pluscategory: getSubCategories[index].childrenData.length > numberCategoryHide && listCategoryHide.includes(getSubCategories[index].id), hiddenVoice: !navigationHide.includes(getSubCategories[index].id)}">
        <li
        class="navbar-item"
        v-for="subcategory in sortedCat(getSubCategories[index].childrenData, ['position'])"
        :key="subcategory.url_path"
        >
          <router-link
          :to="'listing' | localizedByNameCategories(subcategory.url_path, null, $store, $router)"
          class="nav-sublink bcm-link"
          >
            <span class="link-label">{{ subcategory.name }}</span>
          </router-link>
        </li>
        <li class="navbar-item" v-if="viewAllVisible(getSubCategories[index])">
          <a class="nav-sublink bcm-link" @click="changeHiddenVoice(getSubCategories[index].id)">
            <span class="link-label">{{$t('viewall')}}</span>
          </a>
        </li>
        <!-- <li>{{ getSubCategories[index].id }}</li> -->
      </ul>
    </div>
    <div class="nav-images" :class="[`images-columns-${getSubCategories.length}`]">
      <NavImage :category="category" ref="navImage" />
    </div>
  </div>
</template>

<script>
import NavImage from '../NavImage'
import { sortBy, filter } from 'lodash'
import { EventBus } from '@/helpers'
import Config from 'config'

export default {
  name: 'Default',
  props: {
    category: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      animationStart: false,
      animationEnd: false,
      hiddenVoice: true,
      navigationHide: []
    }
  },
  computed: {
    getSubCategories () {
      const _this = this
      // check if category has children and if it hasn't return empty array
      if (_this.category.childrenData.length === 0) {
        return []
      }
      // order category childrens by position and remove the ones that are not active or not included in menu
      _this.category.childrenData.forEach((item) => {
        item.childrenData = sortBy(filter(item.childrenData, { include_in_menu: true, is_active: true }), ['position'])
      })
      return _this.category.childrenData
    },
    labelHiddenVoice (id) {
      if (!this.navigationHide.includes(id)) {
        return this.$t('viewall')
      }
      return ''
    },
    listCategoryHide () {
      return Config.Theme.navigationHideVoiceDesktop
    },
    numberCategoryHide () {
      return Config.Theme.navigationLimitHideVoiceDesktop
    }
  },
  methods: {
    sortedCat (cat, ord) {
      return sortBy(cat, [`${ord}`])
    },
    getSubListItems (child) {
      let subCat = []
      const loop = (arr) => {
        arr.forEach((item) => {
          subCat.push(item)
          if (item.childrenData.length) {
            loop(item.childrenData)
          }
        })
      }
      if (child.length) {
        loop(child)
      }
      return subCat
    },
    changeHiddenVoice (id) {
      EventBus.$emit('TopNavigation::new-height-size', document.querySelector('.wrapp-topbar__content__body__dynamic'))
      this.hiddenVoice = !this.hiddenVoice
      this.navigationHide.push(id)
    },
    viewAllVisible (subcategory) {
      // getSubCategories[index].childrenData.length > numberCategoryHide && !navigationHide.includes(getSubCategories[index].id) && listCategoryHide.includes(getSubCategories[index].id)
      return subcategory.childrenData.length > this.numberCategoryHide && this.listCategoryHide.includes(subcategory.id) && !this.navigationHide.includes(subcategory.id)
    }
  },
  beforeDestroy () {
    this.animationEnd = true
  },
  components: {
    NavImage
  }
}
</script>
